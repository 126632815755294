export const clone = (val) => {
  return JSON.parse(JSON.stringify(val));
}

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = str => str.replace(/(_\w)/g, m => m[1].toUpperCase());

export const everyPropKey = (obj, fn) => {
  const objEntries = Object.entries(obj)
  const newObj = {}
  objEntries.forEach(([key, value]) => {
    newObj[fn(key)] = value
  })
  return newObj
}

export const staySelectedProps = (obj, arrOfProps, defaultValue = null) => {
  const selectedProps = arrOfProps.map(item => {
    // [0] - key, [1] - value, [2] - rename
    if (Array.isArray(item) && item.length) {
      return [item[2] || item[0], obj[item[0]] || item[1]]
    } else if (!item.length){
      return null
    }
    return [item, obj[item] !== undefined ? obj[item] : defaultValue]
  }).filter(item => item)
  
  return Object.fromEntries(selectedProps)
}

export const utcDateTimeToStandardFormat = (dateTime, cutSeconds = true) => {
  if (typeof dateTime === 'string') {
    const [date, time] = dateTime.split('T')
    
    return `${date} ${time.substring(0, cutSeconds ? 5 : 8)}`
  }
  return null
}
